import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

export const Input = ({className, onChange, onKeyPress, type, value, required, placeholder, ...props }) => {
  const handleChange = (event) => onChange?.(event);
  const handleKeyPress = (event) => onKeyPress?.(event);
    return (
      <TextField value={value} className={className} onChange={handleChange} onKeyPress={handleKeyPress} type={type} required={required} placeholder={placeholder} {...props} />
    );
  };

Input.propTypes = {
  /**
   * Text to use as a label
   */
  label: PropTypes.string,
  /**
   * Value controlled externally to component
   */
  value: PropTypes.string,
  /**
   * Change handler
   */
  onChange: PropTypes.func,
  /**
   * KeyPress handler
   */
  onKeyPress: PropTypes.func,
};

Input.defaultProps = {};