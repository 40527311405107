import axios from "axios";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function RequireAuth() {
  const token = sessionStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = token;
  if (!token) {
    return <Navigate to={"/"} replace />;
  }
  return <Outlet />;
}

export default RequireAuth;
