import { LinearProgress, CircularProgress } from '@mui/material';

export default function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <LinearProgress color="inherit" className='libearloader' />
            <CircularProgress color="inherit" className='circleloader' />
            {/* <div className="loading-spinner"></div> */}
        </div>
    );
}