import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Link,
  CssBaseline,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import { toast } from "react-toastify";

const theme = createTheme();

function NotFoundPage() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleLinkClick = () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get(`${process.env.REACT_APP_APIURL}/users/me`, {}, {})
        .then(function (response) {
          if (response.data.status === 1) {
            if (response.data.result.role === "User") navigate("/home");
            else navigate("/appstore");
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.error.statusCode !== 401)
            toast.error(error.response.data.error.message);
          setIsLoading(false);
        });
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading ? <LoadingSpinner /> : <></>}

        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              404 Not Found
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <p></p>
              <Link
                to={"/"}
                variant="body2"
                onClick={handleLinkClick}
                style={{ cursor: "pointer" }}
              >
                Goto Home
              </Link>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default NotFoundPage;
