import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "./LoadingSpinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme();

function Home() {
  let navigate = useNavigate();
  const IsLogin = sessionStorage.getItem("token") ? true : false;
  const [isLoading, setIsLoading] = useState(false);

  if (!IsLogin) navigate("#/");

  useEffect(() => {
    GetCurrentUser();
  }, []);

  const GetCurrentUser = async () => {
    var token = sessionStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      await axios
        .get(`${process.env.REACT_APP_APIURL}/users/me`, {}, {})
        .then(function (response) {
          if (response.data.status === 1) {
            if (response.data.result.role === "User")
              window.location.hash = "/home";
            else window.location.hash = "/appstore";
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.error.statusCode !== 401)
            toast.error(error.response.data.error.message);
          setIsLoading(false);
        });
    } else window.location.hash = "/";
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading ? <LoadingSpinner /> : <></>}
      </ThemeProvider>
    </>
  );
}

export default Home;
