import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from "../LoadingSpinner";

// import { toast } from 'react-toastify';

import './drop-file-input.css';
import uploadImg from '../../Images/cloud-upload-regular-240.png';

const DropFileInput = props => {
    const [isLoading, setIsLoading] = useState(false);
    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState(props.files ? [props.files] : []);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        setIsLoading(true);
        const newFile = e.target.files[0];

        if (newFile) {
            let updatedList = fileList;
            if (props.multiple !== true)
                updatedList = [newFile];
            else
                updatedList = [...fileList, newFile];

            setFileList(updatedList);
            props.onFileChange(updatedList);

            // if (props.imgwidth > 0 && props.imgheight > 0) {
            //     var reader = new FileReader();
            //     reader.onload = (r) => {
            //         let img = new Image();
            //         img.src = r.target.result;
            //         img.onload = function () {
            //             if (props.imgwidth !== this.width && props.imgheight !== this.height) {
            //                 e.target.value = null;
            //                 toast.warn(`Image size must be ${props.imgwidth} X ${props.imgheight} (width X height).`);
            //                 setFileList([]);
            //                 props.onFileChange([]);
            //                 return false;
            //             }
            //             else {
            //                 let updatedList = fileList;
            //                 if (props.multiple !== true)
            //                     updatedList = [newFile];
            //                 else
            //                     updatedList = [...fileList, newFile];

            //                 setFileList(updatedList);
            //                 props.onFileChange(updatedList);
            //             }
            //         };
            //         setIsLoading(false);
            //     };
            //     reader.readAsDataURL(newFile);
            // }
            // else {
            //     let updatedList = fileList;
            //     if (props.multiple !== true)
            //         updatedList = [newFile];
            //     else
            //         updatedList = [...fileList, newFile];

            //     setFileList(updatedList);
            //     props.onFileChange(updatedList);
            // }
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
            e.target.value = null;
            setFileList([]);
            props.onFileChange([]);
        }
    }

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    }

    return (
        <>
            {isLoading ? <LoadingSpinner /> : <></>}

            <div ref={wrapperRef} className="drop-file-input" onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                <div className="drop-file-input__label">
                    <img src={uploadImg} alt="" />
                    {fileList.length > 0 ?
                        fileList.map((item, index) => (
                            <div key={index} className="drop-file-preview__item">
                                <div className="drop-file-preview__item__info">
                                    <p>{item.name}</p>
                                </div>
                                <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                            </div>
                        ))
                        :
                        <p>Drag & Drop your files here</p>
                    }
                </div>
                <input type="file" onChange={onFileDrop} accept={props.accept} multiple={props.multiple} />
            </div>
        </>
    );
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func
}

export default DropFileInput;