import { Container, createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme();

function TermsAndConditioms() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xl">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras semper placerat mauris, ac tempor metus. Pellentesque nec vestibulum turpis. Morbi vestibulum nulla vitae varius volutpat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed risus nibh, consequat eget turpis ac, faucibus porttitor risus. Aliquam id arcu ornare, convallis justo tempus, pharetra nisl. Mauris rhoncus odio ut enim sodales, eget consequat magna consequat. Mauris facilisis, eros ut congue interdum, nibh leo pellentesque nunc, ac suscipit turpis libero sit amet eros. Pellentesque et interdum mauris, sit amet varius sapien. Maecenas eu rutrum nulla, vitae vehicula leo. Pellentesque eu porta neque, a tincidunt urna.
                    </p>
                    <p>
                        Donec condimentum eros eget tempus sagittis. Ut rhoncus egestas est, sed pulvinar velit viverra eget. Duis ac eros et arcu egestas hendrerit quis sit amet orci. Nullam vitae tortor in felis elementum malesuada. Aliquam erat volutpat. Cras id velit nulla. Phasellus tempus, dui vitae sollicitudin aliquet, mauris nulla pharetra augue, a fringilla arcu nisi a nibh. Donec cursus auctor semper. Vivamus eu est est. Suspendisse potenti.
                    </p>
                    <p>
                        Donec sed congue risus, sed consequat leo. In lorem velit, tincidunt nec ipsum nec, rutrum tempus velit. Nullam euismod erat nec nibh tincidunt rhoncus. Maecenas lorem velit, interdum volutpat tempus eu, tempus nec leo. Vestibulum dictum dolor at est volutpat tristique. Vestibulum vel facilisis nisi. Quisque eu ante diam. Nunc tortor sem, luctus facilisis pretium at, tempor eget justo. Cras eget metus volutpat, porttitor ante sed, euismod erat. Pellentesque semper dui non neque laoreet placerat. Aliquam in tellus dictum, tincidunt velit in, mollis massa. Suspendisse nec mi ut felis convallis tempus. Suspendisse et ligula at orci aliquam placerat et at mauris. Nullam scelerisque lorem luctus, consectetur diam vitae, consequat magna. Cras volutpat ante vel accumsan dapibus.
                    </p>
                    <p>
                        Curabitur eu justo massa. Vivamus sed magna ex. Donec lobortis, nulla eget lacinia aliquet, ex tortor consectetur tellus, vitae porttitor odio massa at elit. Morbi lectus purus, fermentum quis dui nec, lobortis imperdiet leo. Quisque vulputate nisl sed eros mattis laoreet. Vestibulum vel consequat velit. Cras turpis nunc, suscipit vitae tristique nec, aliquam sit amet dui. Duis dui est, varius at elementum vel, convallis blandit metus. Aliquam sodales suscipit porttitor. Praesent vel tristique augue, sit amet placerat elit. Nunc et rutrum libero, sed malesuada magna. Aenean quis elit iaculis, consectetur nibh eget, convallis tellus.
                    </p>
                    <p>
                        Morbi eleifend mi sit amet porta condimentum. In dignissim augue vitae nulla euismod, in tincidunt eros vehicula. Fusce venenatis erat nec dolor feugiat maximus. Etiam viverra quam tortor, non lacinia tellus laoreet at. Etiam sit amet urna sed dui accumsan porta. Sed facilisis mi commodo nibh viverra vulputate. Sed auctor, diam sit amet interdum auctor, felis dui tempus tortor, a maximus magna massa et massa. Suspendisse condimentum elit et neque scelerisque, vehicula ultrices felis semper.
                    </p>
                </Container>
            </ThemeProvider>
        </>
    );
}

export default TermsAndConditioms;