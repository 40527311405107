import { useEffect, useState, Fragment } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Stack,
} from "@mui/material";
import {
  Add as AddIcon,
  CloseOutlined as CloseOutlinedIcon,
  Edit as EditIcon,
  Visibility,
} from "@mui/icons-material";
import { Input } from "../stories/Input/Input.jsx";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import DropFileInput from "./DropFileInput/DropFileInput";
import { toast } from "react-toastify";

function AppLauncher() {
  const steps = [
    "Select campaign settings",
    "Create an ad group",
    "Create an ad",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [open, SetAddNew] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [Products, SetProducts] = useState([]);
  const [Product, SetProduct] = useState({
    id: "",
    name: "",
    description: "",
    packagename: "",
    category: "",
    device: "",
    layer: "",
    graphicsassetsfile: {},
    apkfile: {},
    version: "",
  });
  const [Search, SetSearch] = useState({
    search: "",
    searchcategory: "",
    searchdevice: "",
    searchlayer: "",
  });
  const [IsSearch, SetIsSearch] = useState({
    search: "",
    searchcategory: "",
    searchdevice: "",
    searchlayer: "",
  });
  const itemsPerPage = 5;
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const Categories = [
    "Synchronous Learning",
    "Early Childhood Enlightenment",
    "Quality Development",
    "Educational Entertainment",
    "Common Tool",
  ];
  const Devices = ["Desktop", "Glass"];
  const Layers = ["Featured", "Recommended", "Must Try"];

  const SearchProduct = async (skip = 0, search, category, device, layer) => {
    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .get(
        `${process.env.REACT_APP_APIURL}/products/${itemsPerPage}/${skip}?search=${search}&category=${category}&device=${device}&layer=${layer}`
      )
      .then(function (response) {
        if (response.data.status === 1) {
          SetProducts(response.data.result.Product);
          setPageCount(Math.ceil(response.data.result.Count / itemsPerPage));
          setPage((skip + itemsPerPage) / itemsPerPage);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        if (error.response.data.error.statusCode === 403) {
          window.location.hash = "/home";
        }
        setIsLoading(false);
      });
  };

  const GetProduct = async (skip = 0) => {
    SearchProduct(
      skip,
      Search.search,
      Search.searchcategory,
      Search.searchdevice,
      Search.searchlayer
    );
    SetIsSearch({
      search: Search.search,
      searchcategory: Search.searchcategory,
      searchdevice: Search.searchdevice,
      searchlayer: Search.searchlayer,
    });
  };

  const handlePageClick = async (event, value) => {
    await SearchProduct(
      (value - 1) * itemsPerPage,
      IsSearch.search,
      IsSearch.searchcategory,
      IsSearch.searchdevice,
      IsSearch.searchlayer
    );
  };

  useEffect(() => {
    if (!sessionStorage.getItem("token")) window.location.hash = "/";
    SearchProduct(0, "", "", "", "");
  }, []);

  const AddNew = () => {
    SetAddNew(true);
    setActiveStep(0);
    SetProduct({
      ...Product,
      id: undefined,
      name: "",
      description: "",
      packagename: "",
      category: "",
      device: "",
      layer: "",
      graphicsassetsfile: {},
      apkfile: {},
      version: "",
    });
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      if (!Product.id && !Product.name) {
        toast.warn("Please enter name.");
        return;
      }
      if (!Product.id && !Product.description) {
        toast.warn("Please enter description.");
        return;
      }
      if (!Product.id && !Product.packagename) {
        toast.warn("Please enter package name.");
        return;
      }
      if (!Product.category) {
        toast.warn("Please select category.");
        return;
      }
      if (!Product.device) {
        toast.warn("Please select device.");
        return;
      }
      if (!Product.layer) {
        toast.warn("Please select layer.");
        return;
      }

      if (Layers.length > 0) {
        if (Product.layer === Layers[0])
          // Featured
          SetProduct({ ...Product, assetwidth: 432, assetheight: 72 });
        else if (Product.layer === Layers[1])
          // Recommended
          SetProduct({ ...Product, assetwidth: 280, assetheight: 98 });
        else if (Product.layer === Layers[2])
          // Must Try
          SetProduct({ ...Product, assetwidth: 288, assetheight: 98 });
      }

      if (Product.id) return await Update();
    } else if (activeStep === 1 && !(Product.graphicsassetsfile.size > 0)) {
      toast.warn("Please upload graphics assets.");
      return;
    } else if (activeStep === 2) {
      if (!(Product.apkfile.size > 0)) {
        toast.warn("Please upload apk.");
        return;
      }
      if (!Product.version) {
        toast.warn("Please enter version.");
        return;
      }
    }

    if (activeStep === 2) Save();
    else {
      if (activeStep === 0) {
        axios.defaults.headers.common["Authorization"] =
          sessionStorage.getItem("token");
        await axios
          .get(
            `${process.env.REACT_APP_APIURL}/product/productexists?id=${Product.id}&name=${Product.name}`,
            {},
            {}
          )
          .then(function (response) {
            if (response.data.status === 1) {
              setActiveStep(activeStep + 1);
            } else toast.warn(response.data.message);
            setIsLoading(false);
          })
          .catch(function (error) {
            toast.error(error.response.data.error.message);
            if (error.response.data.error.statusCode === 403) {
              window.location.hash = "/home";
            }
            setIsLoading(false);
          });
      } else setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleInputs = (event) => {
    SetProduct({ ...Product, [event.target.name]: event.target.value });
  };

  const handleSearch = async (event) => {
    SetSearch({ ...Search, [event.target.name]: event.target.value });
  };

  const SearchClear = async () => {
    SetSearch({
      search: "",
      searchcategory: "",
      searchdevice: "",
      searchlayer: "",
    });
    SearchProduct(0, "", "", "", "");
    SetIsSearch({
      search: "",
      searchcategory: "",
      searchdevice: "",
      searchlayer: "",
    });
  };

  const handleassetfile = (files) => {
    SetProduct({
      ...Product,
      graphicsassetsfile: files.length > 0 ? files[0] : {},
    });
  };

  const handleapkfile = (files) => {
    SetProduct({ ...Product, apkfile: files.length > 0 ? files[0] : {} });
  };

  const Save = async () => {
    let formData = new FormData();
    formData.append("name", Product.name);
    formData.append("description", Product.description);
    formData.append("packagename", Product.packagename);
    formData.append("category", Product.category);
    formData.append("device", Product.device);
    formData.append("layer", Product.layer);
    formData.append("graphicsassetsfile", Product.graphicsassetsfile);
    formData.append("apkfile", Product.apkfile);
    formData.append("version", Product.version);

    setIsLoading(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_APIURL}/save`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then(async function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          setActiveStep(activeStep + 1);
          await GetProduct(0);
          SetAddNew(false);
        } else toast.warn(response.data.message);

        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const Update = async () => {
    if (!Product.category) {
      toast.warn("Please enter category.");
      return;
    }
    if (!Product.device) {
      toast.warn("Please enter device.");
      return;
    }
    if (!Product.layer) {
      toast.warn("Please enter layer.");
      return;
    }

    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .patch(
        `${process.env.REACT_APP_APIURL}/product/update/${Product.id}`,
        {
          category: Product.category,
          device: Product.device,
          layer: Product.layer,
        },
        {}
      )
      .then(async function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          setActiveStep(3);
          await GetProduct(0);
          SetAddNew(false);
        } else toast.warn(response.data.message);

        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const View = async (Details) => {
    SetAddNew(true);
    setActiveStep(0);
    SetProduct(Details);
  };

  return (
    <>
      <div className="launcher">
        {isLoading ? <LoadingSpinner /> : <></>}
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <Box className="row">
            <Box className="col-xl-3 col-lg-3 col-md-6 col-12">
              <Input
                onChange={(e) => handleSearch(e)}
                value={Search.search}
                name="search"
                type="Text"
                autoFocus
                label="Search"
                placeholder="Search"
                margin="normal"
                fullWidth
              />
            </Box>
            <Box className="col-xl-3 col-lg-3 col-md-6 col-12">
              <FormControl fullWidth margin="normal">
                <InputLabel id="searchcategory">Category</InputLabel>
                <Select
                  onChange={(e) => handleSearch(e)}
                  value={Search.searchcategory}
                  name="searchcategory"
                  labelId="demo-simple-select-label"
                  id="searchcategory"
                  label="Category"
                >
                  <MenuItem value="">Select Category</MenuItem>
                  {Categories.map((item, index) => (
                    <MenuItem key={"searchcategory" + index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="col-xl-2 col-lg-2 col-md-6 col-12">
              <FormControl fullWidth margin="normal">
                <InputLabel id="searchdevice">Device</InputLabel>
                <Select
                  onChange={(e) => handleSearch(e)}
                  value={Search.searchdevice}
                  name="searchdevice"
                  labelId="demo-simple-select-label"
                  id="searchdevice"
                  label="Device"
                >
                  <MenuItem value="">Select Device</MenuItem>
                  {Devices.map((item, index) => (
                    <MenuItem key={"searchdevice" + index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="col-xl-2 col-lg-2 col-md-6 col-12">
              <FormControl fullWidth margin="normal">
                <InputLabel id="searchlayer">Layer</InputLabel>
                <Select
                  onChange={(e) => handleSearch(e)}
                  value={Search.searchlayer}
                  name="searchlayer"
                  labelId="demo-simple-select-label"
                  id="searchlayer"
                  label="Layer"
                >
                  <MenuItem value="">Select Layer</MenuItem>
                  {Layers.map((item, index) => (
                    <MenuItem key={"searchlayer" + index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="col-xl-2 col-lg-2 col-md-4 col-12">
              <Stack spacing={2} direction="row">
                <Button
                  onClick={() => {
                    GetProduct(0);
                  }}
                  style={{
                    width: "100px",
                    height: "56px",
                    marginTop: "16px",
                    fontSize: "15px",
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  margin="normal"
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    SearchClear();
                  }}
                  style={{
                    width: "100px",
                    height: "56px",
                    marginTop: "16px",
                    fontSize: "15px",
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  margin="normal"
                >
                  Clear
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box className="table-responsive">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="tablebox table-bordered"
              >
                <TableHead sx={{ background: "#d7dadb" }}>
                  <TableRow>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Image
                    </TableCell>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Description
                    </TableCell>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Package Name
                    </TableCell>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Category
                    </TableCell>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Device
                    </TableCell>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Layer
                    </TableCell>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Version
                    </TableCell>
                    <TableCell sx={{ color: "#000", fontSize: "16px" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Products.map((product, index) => (
                    <TableRow
                      key={"product" + index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <img
                          src={product.graphicsassetsfile}
                          alt="Appliction"
                          border="0"
                          className="appicon"
                        />
                      </TableCell>
                      <TableCell className="text-nowrap">
                        {product.name}
                      </TableCell>
                      <TableCell className="w-300">
                        <span className="content">{product.description}</span>
                      </TableCell>
                      <TableCell>
                        <span className="content">{product.packagename}</span>
                      </TableCell>
                      <TableCell className="w-300">
                        <span className="content">{product.category}</span>
                      </TableCell>
                      <TableCell>{product.device}</TableCell>
                      <TableCell>{product.layer}</TableCell>
                      <TableCell>{product.version}</TableCell>
                      <TableCell>
                        <div className="d-flex justify-content-center">
                          <IconButton
                            onClick={() => {
                              View(product);
                            }}
                            style={{ color: "#22abe1", marginRight: "5px" }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            href={"#/appstore/" + product.id}
                            aria-label="toggle password visibility"
                            edge="end"
                            style={{ color: "#22abe1", marginRight: "5px" }}
                          >
                            {<Visibility />}
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {Products.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={9}
                        style={{ fontSize: "25px", textAlign: "center" }}
                      >
                        No record found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
        <Stack spacing={2} sx={{ float: "left", marginTop: "15px" }}>
          {pageCount > 1 ? (
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageClick}
            />
          ) : (
            <></>
          )}
        </Stack>

        <Fragment>
          <Dialog fullWidth={true} maxWidth={"md"} open={open}>
            <DialogTitle className="contentbox">
              <Box
                sx={{ width: "100%", position: "relative", paddingTop: "40px" }}
              >
                <Stepper activeStep={activeStep}>
                  <Step>
                    <StepLabel>Store Listing</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Assets</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>App Release</StepLabel>
                  </Step>
                </Stepper>
              </Box>
              <IconButton
                onClick={() => {
                  SetAddNew(false);
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#1976d2",
                }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {activeStep === 0 ? (
                <Box sx={{ width: "100%" }} className="step1">
                  <Typography
                    component={"div"}
                    sx={{
                      fontSize: "25px",
                      paddingTop: "30px",
                      paddingBottom: "30px",
                    }}
                  >
                    Product Details
                  </Typography>

                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-12">
                      <Typography component={"div"} sx={{ fontSize: 20 }}>
                        Name *
                      </Typography>
                      <Input
                        onChange={(e) => handleInputs(e)}
                        value={Product.name}
                        disabled={!Product.id ? false : true}
                        name="name"
                        type="Text"
                        autoFocus
                        label="Name *"
                        placeholder="Name *"
                        margin="normal"
                        fullWidth
                      />
                    </div>
                    <div className="col-xl-4 col-md-4 col-12">
                      <Typography component={"div"} sx={{ fontSize: 20 }}>
                        Description *
                      </Typography>
                      <Input
                        onChange={(e) => handleInputs(e)}
                        value={Product.description}
                        disabled={!Product.id ? false : true}
                        name="description"
                        type="Text"
                        autoFocus
                        label="Description *"
                        placeholder="Description *"
                        margin="normal"
                        fullWidth
                      />
                    </div>
                    <div className="col-xl-4 col-md-4 col-12">
                      <Typography component={"div"} sx={{ fontSize: 20 }}>
                        Package Name *
                      </Typography>
                      <Input
                        onChange={(e) => handleInputs(e)}
                        value={Product.packagename}
                        disabled={!Product.id ? false : true}
                        name="packagename"
                        type="Text"
                        autoFocus
                        label="Package Name *"
                        placeholder="Package Name *"
                        margin="normal"
                        fullWidth
                      />
                    </div>
                    <div className="dropdownbox">
                      <Typography
                        component={"div"}
                        sx={{
                          fontSize: "25px",
                          paddingTop: "30px",
                          paddingBottom: "30px",
                        }}
                      >
                        Categorization
                      </Typography>

                      <div className="row">
                        <div className="col-xl-4 col-md-4 col-12">
                          <FormControl fullWidth>
                            <InputLabel id="category">Category</InputLabel>
                            <Select
                              onChange={(e) => handleInputs(e)}
                              value={Product.category}
                              name="category"
                              labelId="demo-simple-select-label"
                              id="category"
                              label="Category"
                            >
                              <MenuItem value="">Select Category</MenuItem>
                              {Categories.map((item, index) => (
                                <MenuItem key={"category" + index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-xl-4 col-md-4 col-12">
                          <FormControl fullWidth>
                            <InputLabel id="device">Device</InputLabel>
                            <Select
                              onChange={(e) => handleInputs(e)}
                              value={Product.device}
                              name="device"
                              labelId="demo-simple-select-label"
                              id="device"
                              label="Device"
                            >
                              <MenuItem value="">Select Device</MenuItem>
                              {Devices.map((item, index) => (
                                <MenuItem key={"device" + index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-xl-4 col-md-4 col-12">
                          <FormControl fullWidth>
                            <InputLabel id="device">Layers</InputLabel>
                            <Select
                              onChange={(e) => handleInputs(e)}
                              value={Product.layer}
                              name="layer"
                              labelId="demo-simple-select-label"
                              id="layers"
                              label="Layers"
                            >
                              <MenuItem value="">Select Layers</MenuItem>
                              {Layers.map((item, index) => (
                                <MenuItem key={"layer" + index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              ) : activeStep === 1 ? (
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: 25,
                      paddingTop: "30px",
                      paddingBottom: "30px",
                    }}
                  >
                    Graphics Assets
                  </Typography>
                  <DropFileInput
                    onFileChange={(files) => handleassetfile(files)}
                    accept="image/*"
                    multiple={false}
                    imgwidth={Product.assetwidth}
                    imgheight={Product.assetheight}
                    files={Product.graphicsassetsfile}
                  />
                </Box>
              ) : activeStep === 2 ? (
                <Box sx={{ width: "100%" }}>
                  <div className="row mt-3">
                    <div className="col-xl-12 col-md-12 col-12">
                      <Typography
                        component={"div"}
                        sx={{ fontSize: 20, marginBottom: 1 }}
                      >
                        APK To Add
                      </Typography>
                      <DropFileInput
                        onFileChange={(files) => handleapkfile(files)}
                        accept=".apk,.xapk"
                        multiple={false}
                        files={Product.apkfile}
                      />
                    </div>
                    <div className="col-xl-3 col-md-3 col-12">
                      <Typography
                        component={"div"}
                        sx={{ fontSize: 20, marginBottom: 1 }}
                      >
                        Release Name
                      </Typography>
                      <Input
                        onChange={(e) => handleInputs(e)}
                        value={Product.version}
                        name="version"
                        type="Text"
                        autoFocus
                        label="Version"
                        placeholder="Version"
                        margin="normal"
                        fullWidth
                        sx={{ marginTop: 0 }}
                      />
                    </div>
                  </div>
                </Box>
              ) : activeStep === 3 ? (
                <Box sx={{ width: "100%" }}>
                  <div className="successfullyblock">
                    <img src="../success.png" alt="success" border="0" />
                    <Typography component={"div"} sx={{ fontSize: 24 }}>
                      Successfully Created your app
                    </Typography>
                  </div>
                </Box>
              ) : (
                <></>
              )}
            </DialogContent>
            <DialogActions>
              <Fragment>
                <Box sx={{ float: "right" }}>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0 || activeStep === 3}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => {
                        handleNext();
                      }}
                      disabled={activeStep === 3}
                      className="stepbtn"
                    >
                      {Product.id
                        ? "Update"
                        : activeStep === steps.length - 1
                        ? "Release App"
                        : "Next"}
                    </Button>
                  </Box>
                </Box>
              </Fragment>
            </DialogActions>
          </Dialog>
          <Fab
            onClick={() => {
              AddNew();
            }}
            title="Add Content"
            className="fixedplusbtn"
            color="primary"
            aria-label="add"
          >
            <AddIcon />
          </Fab>
        </Fragment>
      </div>
    </>
  );
}

export default AppLauncher;
