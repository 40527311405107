import { useCallback, useEffect, useState } from "react";
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  Box,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  ListItemText,
  Link,
  MenuItem,
  Menu,
  Typography,
  styled,
  useTheme,
  Drawer,
} from "@mui/material";
import {
  AccountCircle,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import axios from "axios";
import { ToggleButton } from "../stories/Toggle/ToggleButton.jsx";
import { toast } from "react-toastify";
import "../App.css";
import "react-toastify/dist/ReactToastify.css";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function DrawerLayout() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [IsTencent, setIsTencent] = useState(true);
  const [UserRole, setUserRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // setActiveLink(location.pathname);
    console.log("current location", location);
  }, [location]);

  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const GetCurrentUser = useCallback(async () => {
    var token = sessionStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      await axios
        .get(`${process.env.REACT_APP_APIURL}/users/me`, {}, {})
        .then(function (response) {
          if (response.data.status === 1) {
            setUserRole(response.data.result.role);
            if (response.data.result.role === "Admin") GetUploadStorage();
            if (response.data.result.role === "User")
              window.location.hash = "/home";
            // else window.location.hash = "/appstore";
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.error.statusCode !== 401)
            toast.error(error.response.data.error.message);
          setIsLoading(false);
        });
    } else window.location.hash = "/";
  }, []);

  useEffect(() => {
    GetCurrentUser();
    setActiveLink(location.pathname);
    // handleLinkClick(window.location.hash);
  }, [GetCurrentUser, location.pathname]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const [activeLink, setActiveLink] = useState("link1");
  const handleLinkClick = (link) => {
    if (link.indexOf("#/appstore") !== -1 || link === "#/") link = "#/appstore";

    setActiveLink(link);
  };

  const GetUploadStorage = async () => {
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_APIURL}/upload-storages`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          const IsTencent =
            response.data.result === null
              ? true
              : response.data.result.IsTencent;
          setIsTencent(IsTencent);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response.data.error.statusCode !== 401)
          toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const RequestForRole = async () => {
    if (!window.confirm("Are you sure? You want to request for role change."))
      return;

    var token = sessionStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
      await axios
        .post(`${process.env.REACT_APP_APIURL}/user-roles/rolerequest`, {}, {})
        .then(function (response) {
          if (response.data.status === 1) toast.success(response.data.message);
          else toast.success(response.data.message);
          setIsLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.error.statusCode !== 401)
            toast.error(error.response.data.error.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : <></>}

      <Box sx={{ height: 70, display: "flex" }}>
        <CssBaseline />
        <AppBar
          open={open}
          style={{
            height: 65,
            background: "rgb(34, 171, 225)",
            position: "fixed",
          }}
          className="zidex"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link href={"#/home"} underline="none"></Link>
            </Typography>
            <Box className="profilebox">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                className="profileblock"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorEl)}
                onClick={handleClose}
              >
                <MenuItem>
                  <Link
                    component={NavLink}
                    // onClick={() => handleLinkClick("#/profile")}
                    className={`${activeLink === "/profile" ? "active" : ""}`}
                    // href="#/profile"
                    underline="none"
                    to={"profile"}
                  >
                    <ListItemText primary="Profile" sx={{ fontWeight: 0.85 }} />
                  </Link>
                </MenuItem>
                <MenuItem
                  style={{ display: UserRole === "User" ? "" : "none" }}
                >
                  <Link onClick={RequestForRole} underline="none">
                    <ListItemText
                      primary="Role Request"
                      sx={{ fontWeight: 0.85 }}
                    />
                  </Link>
                </MenuItem>
                <MenuItem
                  // onClick={() => handleLinkClick("#/apikey")}
                  className={`${activeLink === "/apikey" || activeLink === "link1"
                      ? "active"
                      : ""
                    }`}
                  style={{ display: UserRole === "Admin" ? "" : "none" }}
                >
                  <Link to="apikey" underline="none" component={NavLink}>
                    <ListItemText primary="API Key" />
                  </Link>
                </MenuItem>
                <MenuItem onClick={Logout}>
                  <ListItemText primary="Logout" />
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        {/* <Main className='padleft container-fluid'> */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader />
          <Box className="sidebar">
            <div className="d-flex">
              <a href="/">
                <img src="../eye-logo.png" alt="eye-logo" border="0" />
              </a>
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </DrawerHeader>
            </div>
            <ul>
              {UserRole === "User" ? (
                <li>
                  <NavLink
                    // onClick={() => handleLinkClick("#/home")}
                    className={`${activeLink === "#/home" || activeLink === "link1"
                        ? "active"
                        : ""
                      }`}
                    to="home"
                  >
                    Home
                  </NavLink>
                </li>
              ) : (
                <></>
              )}
              {UserRole !== "User" ? (
                <li>
                  <NavLink
                    onClick={() => handleLinkClick("#/appstore")}
                    className={`${activeLink === "#/appstore" || activeLink === "link1"
                        ? "active"
                        : ""
                      }`}
                    to="appstore"
                  >
                    App Store
                  </NavLink>
                </li>
              ) : (
                <></>
              )}
              {UserRole === "Admin" ? (
                <li>
                  <NavLink
                    // // onClick={() => handleLinkClick("#/appmanagement")}
                    className={`${activeLink === "#/appmanagement" ? "active" : ""
                      }`}
                    to="appmanagement"
                  >
                    Role Managment
                  </NavLink>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </Box>
        </Drawer>
        {/* </Main> */}
        <Main open={open} className="container-fluid">
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>
    </>
  );
}

export default DrawerLayout;
