import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import LoadingSpinner from "./LoadingSpinner";
import List from "./DroppableComponents/List";
import { toast } from "react-toastify";
import { Input } from "../stories/Input/Input.jsx";

import "./styles.scss";

function RoleManagement() {
  const [isLoading, setIsLoading] = useState(false);
  const [UserList, setUserList] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [User, setUser] = useState({ roleId: "", fullname: "", mobile: "" });

  const GetAllData = useCallback(async () => {
    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_APIURL}/user-roles/getAllRolesAndUsers`)
      .then(function (response) {
        if (response.data.status === 1) {
          response.data.result.forEach((item) => {
            item.filterdetails = item.details;
          });
          setUserList(response.data.result);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  }, []);

  const GetRoles = useCallback(async () => {
    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_APIURL}/roles`)
      .then(function (response) {
        if (response.data.status === 1) {
          setRoleList(response.data.result);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        // navigate("appstore");
        // window.location.reload();
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    GetAllData();
    GetRoles();
  }, [GetAllData, GetRoles]);

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination || !source) return;

    if (destination.droppableId === source.droppableId) return;

    let sourcedata = UserList.filter((card) => card.id === source.droppableId);

    if (!sourcedata && !(sourcedata.length > 0)) return;

    let destinationuser = sourcedata[0].details.filter(
      (user) => user.id === draggableId
    );

    let List = UserList;
    List.forEach(function (card) {
      if (card.id === destination.droppableId) {
        card.details.push(destinationuser[0]);
      } else if (card.id === source.droppableId) {
        card.details.splice(source.index, 1);
      }
    });
    setUserList(List);
    if (
      destination.droppableId === "Requested" ||
      destination.droppableId === "DeActivated"
    ) {
      setIsLoading(true);
      axios.defaults.headers.common["Authorization"] =
        sessionStorage.getItem("token");
      await axios
        .post(
          `${process.env.REACT_APP_APIURL}/users/updatestatus`,
          { id: draggableId, statusid: destination.droppableId },
          {}
        )
        .then(function (response) {
          if (response.data.status !== 1) toast.success(response.data.message);
          setIsLoading(false);
        })
        .catch(function (error) {
          toast.error(error.response.data.error.message);
          setIsLoading(false);
        });
    }
    // else if (source.droppableId === "Requested" || source.droppableId === "DeActivated") {
    else {
      setIsLoading(true);
      axios.defaults.headers.common["Authorization"] =
        sessionStorage.getItem("token");
      await axios
        .post(
          `${process.env.REACT_APP_APIURL}/user-roles/updaterole/${draggableId}/${destination.droppableId}`,
          {},
          {}
        )
        .then(function (response) {
          setIsLoading(false);
        })
        .catch(function (error) {
          toast.error(error.response.data.error.message);
          setIsLoading(false);
        });
    }
  };

  const SaveUser = async (e) => {
    e.preventDefault();

    if (!User.roleId) {
      toast.warn("Please select role.");
      return;
    }
    if (!User.fullname) {
      toast.warn("Please enter full name.");
      return;
    }
    if (!User.mobile) {
      toast.warn("Please enter mobile number.");
      return;
    }

    setIsLoading(true);
    await axios
      .post(`${process.env.REACT_APP_APIURL}/users/adduser`, User, {})
      .then(async function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);

          setUser({ roleId: "", fullname: "", mobile: "" });
          setUserList([]);
          await GetAllData();
        } else toast.warn(response.data.message);

        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const handleInputs = (e) => {
    setUser({ ...User, [e.target.name]: e.target.value });
  };

  const handleNumberValidation = async (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Box className="launcher">
      {isLoading ? <LoadingSpinner /> : <></>}
      <Box className="col-xl-12 col-lg-12 col-md-12 col-12">
        <Box className="graybox">
          <Box className="row">
            <Box className="col-xl-4 col-lg-4 col-md-6 col-12">
              <FormControl
                fullWidth
                margin="normal"
                style={{ backgroundColor: "#fff" }}
              >
                <InputLabel id="role">Role</InputLabel>
                <Select
                  onChange={(e) => handleInputs(e)}
                  value={User.roleId}
                  name="roleId"
                  labelId="demo-simple-select-label"
                  id="role"
                  label="Role"
                >
                  <MenuItem value="">Select Role</MenuItem>
                  {RoleList.map((item, index) => (
                    <MenuItem key={"role" + index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="col-xl-3 col-lg-3 col-md-3 col-12">
              <Input
                onChange={(e) => handleInputs(e)}
                value={User.fullname}
                name="fullname"
                type="text"
                autoFocus
                label="Full Name"
                placeholder="Full Name"
                required={true}
                fullWidth
                style={{ height: "40px", marginTop: "15px", fontSize: "15px" }}
              />
            </Box>
            <Box className="col-xl-3 col-lg-3 col-md-3 col-12">
              <Input
                onChange={(e) => handleInputs(e)}
                onKeyPress={(e) => {
                  handleNumberValidation(e);
                }}
                value={User.mobile}
                maxLength={12}
                name="mobile"
                type="text"
                autoFocus
                label="Mobile Number"
                placeholder="Mobile Number"
                required={true}
                fullWidth
                style={{ height: "40px", marginTop: "15px", fontSize: "15px" }}
              />
            </Box>
            <Box className="col-xl-2 col-lg-2 col-md-2 col-12">
              <Button
                onClick={SaveUser}
                style={{
                  width: "150px",
                  height: "52px",
                  marginTop: "16px",
                  fontSize: "15px",
                }}
                type="submit"
                fullWidth
                variant="contained"
                margin="normal"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"app"} type="list" direction="horizontal">
          {(provided) => (
            <div
              className="wrapperbox row"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {UserList.map((user, index) => {
                return <List list={user} key={user.id} index={index} />;
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

export default RoleManagement;
