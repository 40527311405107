import { useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  ContentCopy as ContentCopyIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";
import { toast } from "react-toastify";
const theme = createTheme();

function APIKey() {
  const [APIKeys, setAPIKeys] = useState([]);
  const [confirmtoggle, setconfirmtoggle] = useState(false);
  const [keyid, setkeyid] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .post(
        `${process.env.REACT_APP_APIURL}/securities`,
        { name: "APIKey" },
        {}
      )
      .then(function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          GetKeys();
        } else toast.warn(response.data.message);
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const HandleDelete = (id) => {
    setconfirmtoggle(true);
    setkeyid(id);
  };

  const handleCancel = () => {
    setconfirmtoggle(false);
  };

  const handleOk = async () => {
    setconfirmtoggle(false);

    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .delete(`${process.env.REACT_APP_APIURL}/securities/${keyid}`, {})
      .then(function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          GetKeys();
        } else toast.warn(response.data.message);

        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetKeys();
  }, []);

  const GetKeys = async () => {
    setIsLoading(true);
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_APIURL}/securities`, {}, {})
      .then(function (response) {
        if (response.data.result && response.data.result.length > 0)
          response.data.result.forEach((key) => {
            key.ToggleKey = false;
            key.displayvalue = new Array(key.value.length + 1).join("x");
          });
        setAPIKeys(response.data.result);
        setIsLoading(false);
      })
      .catch(function (error) {
        toast.error(error.response.data.error.message);
        setIsLoading(false);
      });
  };

  const HandleToggleKey = async (ToggleKey, index) => {
    setAPIKeys((current) =>
      current.map((obj, i) => {
        if (i === index)
          return {
            ...obj,
            ToggleKey: ToggleKey === false ? true : false,
            displayvalue:
              ToggleKey === false
                ? obj.value
                : new Array(obj.value.length + 1).join("x"),
          };
        return obj;
      })
    );
  };

  const HandleCopy = async (index) => {
    var copytextField = document.createElement("textarea");
    copytextField.innerText = APIKeys[index].value;
    document.body.appendChild(copytextField);
    copytextField.select();
    document.execCommand("copy");
    copytextField.remove();
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading ? <LoadingSpinner /> : <></>}

        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xs"
          open={confirmtoggle}
        >
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent dividers>
            {" "}
            Are You sure want to delete API Key?
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </DialogActions>
        </Dialog>

        <Box className="Loginbox apibox">
          <Container
            component="main"
            maxWidth="xs"
            sx={{ boxShadow: 3, borderRadius: 5 }}
          >
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box className="loginlogo">
                <a href="/">
                  <img src="../eye-logo.png" alt="eye-logo" border="0" />
                </a>
              </Box>
              <Typography component="h1" variant="h5">
                Generate API Key
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                {APIKeys.length > 0 ? (
                  APIKeys.map((apikey, index) => (
                    <div
                      className="row d-flex justify-content-between"
                      key={index}
                    >
                      <div className="col-lg-8 ">
                        <label htmlFor="">{apikey.displayvalue}</label>
                      </div>
                      <div className="col-lg-4">
                        <div className="d-flex">
                          <IconButton
                            className="dailogicon ms-2"
                            aria-label="toggle password visibility"
                            onClick={() => {
                              HandleToggleKey(apikey.ToggleKey, index);
                            }}
                            edge="end"
                          >
                            {apikey.ToggleKey === true ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                          <IconButton
                            className="dailogicon"
                            onClick={(e) => HandleCopy(index)}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                          <IconButton
                            className="dailogicon"
                            onClick={(e) => HandleDelete(apikey.id)}
                          >
                            <DeleteForeverIcon sx={{ color: "red" }} />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
                <Button
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Generate API Key
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default APIKey;
