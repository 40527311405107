import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

import Card from "../Card";
import { Input } from "../../../stories/Input/Input.jsx";

import "./styles.scss";

export default function List({ list, index }) {
  const [filteredList, setFilteredList] = useState(list.details);
  const [search, setsearch] = useState("");

  useEffect(() => {
    let filterdata = list.details;

    if (search)
      filterdata = list.details.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );

    setFilteredList(filterdata);
  }, [search, list.details]);

  const handleSearch = async (event) => {
    setsearch(event.target.value);
  };

  return (
    <Draggable draggableId={list.id} index={index}>
      {(provided) => (
        <div
          className="col"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="list-cards" {...provided.dragHandleProps}>
            <div className="title-list">
              <div className="editable-title-container">
                <h2 className="editable-title">{list.name}</h2>
                {list.details.length > 0 ? (
                  <Input
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    value={search}
                    maxLength={12}
                    name="search"
                    type="text"
                    autoComplete="text"
                    autoFocus
                    label="Search"
                    placeholder="Search"
                    size="small"
                    fullWidth
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="container-cards">
              <Droppable droppableId={list.id} type="task">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="card-container"
                  >
                    {filteredList.map((card, index) => {
                      return (
                        <Card
                          key={card.id}
                          card={card}
                          index={index}
                          listId={list.id}
                        />
                      );
                    })}
                    {filteredList.length === 0 ? (
                      <p>No record found.</p>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
