import { Route, Routes } from "react-router-dom";
import APIKey from "./components/APIKey";
import AppLauncher from "./components/AppLauncher";
import Home from "./components/Home";
import UploadFile from "./components/Uploadfile";
import NotFoundPage from "./components/NotFoundPage";
import AppManagement from "./components/RoleManagement";
import DrawerLayout from "./layouts/DrawerLayout";
import RequireAuth from "./components/RequireAuth";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import TermsAndConditioms from "./components/TermsAndConditioms";
import { ToastContainer} from "react-toastify";

function AppRoute() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Routes>
        <Route index path="/" element={<Signin />} />
        <Route path="signin" element={<Signin />} />
        <Route path="signup" element={<Signup />} />
        <Route path="termsandconditioms" element={<TermsAndConditioms />} />
        {/* require authentication and Authorization for request */}
        <Route element={<RequireAuth />}>
          <Route element={<DrawerLayout />}>
            <Route path="home" element={<Home />} />
            <Route path="apikey" element={<APIKey />} />
            <Route path="/" element={<AppLauncher />} />
            <Route path="appstore" element={<AppLauncher />} />
            <Route path="appmanagement" element={<AppManagement />} />
            <Route path="appstore/:ProductId" element={<UploadFile />} />
            <Route path="notfound" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default AppRoute;
