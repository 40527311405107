import { Draggable } from "react-beautiful-dnd";

import "./styles.scss";

export default function Card({ card, index, listId }) {
  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div className="card-content">
            <div className="card-title-container">
              <p>{card.name}</p>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
