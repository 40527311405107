import React from "react";
import PropTypes from "prop-types";
import "./ToggleButton.css";

export const ToggleButton = ({ ...props }) => {
  const { id, onClick, lefttext, righttext, checked } = props
  const handleChange = (event) => onClick?.(event);

  return (
        <div className="onoffswitch">
          <input onClick={handleChange} type="checkbox" id={`myonoffswitch${id}`} className="onoffswitch-checkbox" />
          <label htmlFor={`myonoffswitch${id}`} className={checked === true ? 'activeblock' : 'deactiveblock'}>
              <span className="activecolor">{checked === true ? lefttext : righttext}</span>
              <span className="onswitch-switch-button"></span>
          </label>
        </div>
  );
};

ToggleButton.propTypes = {
  lefttext: PropTypes.string,
  righttext: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool
};

ToggleButton.defaultProps = {};